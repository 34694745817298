<template>
  <v-card-text class="pricing-free-trial my-5">
    <v-card-text class="text-center">
      <p class="text-xl font-weight-medium text--primary mb-1">
        {{ $t('needMoreHelp') }}
      </p>
      <p>{{ $t('needMoreHelpText') }}</p>
    </v-card-text>

    <v-row class="mx-auto">
      <v-col
        cols="12"
        sm="3"
        md="3"
      >
        <v-card
          class="faq-contact text-center pa-5 hovering"
          flat
          style="cursor: pointer"
          @click="goToEmail"
        >
          <v-avatar
            rounded
            color="secondary"
            size="48"
            class="v-avatar-light-bg mb-6"
          >
            <v-icon
              class="text--primary"
              size="36"
            >
              {{ icons.mdiEmailOutline }}
            </v-icon>
          </v-avatar>

          <p class="text-xl text--primary font-weight-medium mb-2">
            admin@marketa.id
          </p>

          <p class="text-sm mb-0">
            {{ $t('needMoreHelpTextEmail') }}
          </p>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        sm="3"
        md="3"
      >
        <v-card
          class="faq-contact text-center pa-5 hovering"
          flat
          style="cursor: pointer"
          @click="goToWa"
        >
          <v-avatar
            rounded
            color="secondary"
            size="48"
            class="v-avatar-light-bg mb-6"
          >
            <v-icon
              class="text--primary"
              size="36"
            >
              {{ icons.mdiWhatsapp }}
            </v-icon>
          </v-avatar>

          <p class="text-xl text--primary font-weight-medium mb-2">
            admin-Marketa
          </p>

          <p class="text-sm mb-0">
            {{ $t('needMoreHelpTextEmail') }}
          </p>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        sm="3"
        md="3"
      >
        <v-card
          class="faq-contact text-center pa-5 hovering"
          flat
          style="cursor: pointer"
          @click="goToYt"
        >
          <v-avatar
            rounded
            color="secondary"
            size="48"
            class="v-avatar-light-bg mb-6"
          >
            <v-icon
              class="text--primary"
              size="36"
            >
              {{ icons.mdiYoutube }}
            </v-icon>
          </v-avatar>

          <p class="text-xl text--primary font-weight-medium mb-2">
            Marketa Indonesia
          </p>

          <p class="text-sm mb-0">
            {{ $t('needMoreHelpTextYt') }} <br />
          </p>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        sm="3"
        md="3"
      >
        <v-card
          class="faq-contact text-center pa-5 hovering"
          flat
          style="cursor: pointer"
          @click="goToIg"
        >
          <v-avatar
            rounded
            color="secondary"
            size="48"
            class="v-avatar-light-bg mb-6"
          >
            <v-icon
              class="text--primary"
              size="36"
            >
              {{ icons.mdiInstagram }}
            </v-icon>
          </v-avatar>

          <p class="text-xl text--primary font-weight-medium mb-2">
            @marketa.idn
          </p>

          <p class="text-sm mb-0">
            {{ $t('needMoreHelpTextIg') }} <br />
          </p>
        </v-card>
      </v-col>
    </v-row>
  </v-card-text>
</template>

<script>
import { mdiEmailOutline, mdiInstagram, mdiWhatsapp, mdiYoutube } from '@mdi/js'

export default {
  setup() {
    return {
      icons: { mdiEmailOutline, mdiYoutube, mdiWhatsapp, mdiInstagram },
    }
  },
  mounted() {
    // let str =
    //   'aaaaacaaaaaaaoaksoaksoak asojsoiajsoaj [[trackasasasasasasas]] aaaaaaaaaaaaaaaaaaasapklspa aopskaoksoaks [[track]]'
    // let tmp = ''
    // for (let i = 0; i < str.length; i++) {
    //   if (str[i] === '[' && str[i - 1] === '[') {
    //     let check = ''
    //     for (let j = i + 1; j < str.length; j++) {
    //       check += str[j]
    //     }
    //     tmp = check
    //   }
    // }
    // let tmp2 = ''
    // for (let a = tmp.length - 1; a >= 0; a--) {
    //   tmp2 += tmp[a]
    // }
    // let tmp3 = ''
    // for (let i = 0; i < tmp2.length; i++) {
    //   if (tmp2[i] === ']' && tmp2[i - 1] === ']') {
    //     let check = ''
    //     for (let j = i + 1; j < tmp2.length; j++) {
    //       check += tmp2[j]
    //     }
    //     tmp3 = check
    //   }
    // }
    // let tmp4 = ''
    // for (let a = tmp3.length - 1; a >= 0; a--) {
    //   tmp4 += tmp3[a]
    // }
    // let tmp5 = str
    // const test3 = tmp5.replace(`[[${tmp4}]]`, 'https:78080')
    // //console.log(test3, ' INI LENGTH')
  },
  methods: {
    goToWa() {
      const encoded = encodeURI(`https://wa.me/6285875206870`)

      // https://www.instagram.com/info.superwa/
      window.open(encoded, '_blank')
    },
    goToIg() {
      const encoded = encodeURI(`https://www.instagram.com/marketa.idn/`)

      // https://www.instagram.com/info.superwa/
      window.open(encoded, '_blank')
    },
    goToEmail() {
      const encoded = encodeURI(`mailto: admin@marketa.id`)

      // https://www.instagram.com/info.superwa/
      window.open(encoded, '_blank')
    },
    goToYt() {
      const encoded = encodeURI(`https://www.youtube.com/channel/UCr7biJXrOEyNY7GnteFIa6A`)

      // https://www.instagram.com/info.superwa/
      window.open(encoded, '_blank')
    },
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/mixins';
@import '@core/preset/preset/variables';
.hovering:hover {
  background-color: #2d2c2e3a;
  transition: all 0.2s ease-in-out;
  transform: scale(1.1);
}

.faq-contact {
  min-height: 200px;
}

.sticky-card {
  width: 344px;
  position: fixed;
  top: 90px;
  z-index: 1;
  /* centering */
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
}

#pricing-plan {
  .popular:hover {
    border-color: rgba(145, 85, 253, 0.3);
  }

  .pricing-free-trial {
    background-color: rgba(145, 85, 253, 0.04);
    position: relative;
    height: 14.625rem;
    .pricing-trial-img {
      position: relative;
      top: -3.5rem;
      right: 0;
    }
  }
  .pricing-accordion {
    .v-expansion-panels {
      .v-expansion-panel {
        box-shadow: none !important;
        margin-bottom: -1px;
        &::before {
          box-shadow: none;
        }
      }
    }
  }
}
.mobile-btn {
  display: none;
}
@media (max-width: 960px) {
  #pricing-plan {
    .pricing-free-trial {
      height: auto;
      .pricing-trial-img {
        bottom: -1.2rem;
        top: unset;
      }
    }
  }
}
@media (max-width: 560px) {
  .log-out-text {
    display: none;
  }
  .mobile-btn {
    display: block;
  }
}

@include theme--child(pricing-accordion) using ($material) {
  .v-expansion-panels {
    .v-expansion-panel {
      border: 1px solid map-deep-get($material, 'dividers');
    }
  }
}
.pricing-style {
  padding: 10px;
  border: 1px solid #dbdbdb;
  border-radius: 5px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  flex-flow: column wrap;
}
/* Hide scrollbar for IE, Edge and Firefox */
.v-dialog {
  -ms-overflow-style: none /* IE and Edge */ !important;
  scrollbar-width: none /* Firefox */ !important;
}

.strikethrough {
  text-decoration: line-through;
}

.v-dialog::-webkit-scrollbar {
  display: none;
}
</style>
